.Views {
  text-align: center;
}

/* .Views-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
 */

.Views-body {
  background-color: white;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Views-container {
  background-color: white;
  min-height: 80vh;
  display: inline-flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(9px + 1vmin);
  color: black;
  margin-top: 10px;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.Views-contact {
  background-color: white;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
}

.Views-h {
  border-bottom: solid 3px gray;
}

.Views-footer {
  background-color: #3B5998;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Chrome, Safari */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* FireFox, IE */
input[type="number"] {
  -moz-appearance:textfield;
}